// 通用样式
html,
body {
	// font-size: 100px;
	font-family: 'Times New Roman', 'Microsoft YaHei', SimHei;
	scroll-behavior: smooth;
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}
	li {
		margin: 0;
		padding: 0;
	}
	.swiper-button-next,
	.swiper-button-prev {
		&:after {
			color: #999;
		}
	}
}